import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './mark.css'

const Mark = (props) => {
  return (
    <div className="mark-mark">
      <div className="mark-icon1">
        <svg viewBox="0 0 1024 1024" className="mark-icon2">
          <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
        </svg>
      </div>
      <p>
        {props.label1 ?? (
          <Fragment>
            <p className="mark-label2">
              <span>Duis aute irure dolor in reprehenderit</span>
            </p>
          </Fragment>
        )}
      </p>
    </div>
  )
}

Mark.defaultProps = {
  label1: undefined,
}

Mark.propTypes = {
  label1: PropTypes.element,
}

export default Mark
