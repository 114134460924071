import React from 'react'

import PropTypes from 'prop-types'

import './faq.css'

const FAQ = (props) => {
  return (
    <div className={`faq-accordion ${props.rootClassName} `}>
      <div
        data-role="accordion-container"
        className="faq-element1 accordion-element"
      >
        <div className="faq-details1">
          <span className="faq-text10">Does this really work?</span>
          <span data-role="accordion-content" className="faq-text11">
            Yes, this method of advertising is called &quot;Astroturfing&quot; -
            when you seed brand mentions to attract organic customers into your
            sales funnel. We provide a volunteer network of human verified
            accounts to post from. The traffic is real because you decide where.
          </span>
        </div>
        <div data-role="accordion-icon">
          <svg viewBox="0 0 1024 1024" className="faq-icon10">
            <path d="M366 708l196-196-196-196 60-60 256 256-256 256z"></path>
          </svg>
        </div>
      </div>
      <div
        data-role="accordion-container"
        className="faq-element2 accordion-element"
      >
        <div className="faq-details2">
          <span className="faq-text12">What is direct response marketing?</span>
          <span data-role="accordion-content" className="faq-text13">
            TweetMob is ideal for direct response marketing - when your
            marketing language persuades prospects to take action right now. Get
            immediate measurable results and iterate your message quickly. Our
            AI campaign generator will show you how. 
          </span>
        </div>
        <div data-role="accordion-icon">
          <svg viewBox="0 0 1024 1024" className="faq-icon12">
            <path d="M366 708l196-196-196-196 60-60 256 256-256 256z"></path>
          </svg>
        </div>
      </div>
      <div
        data-role="accordion-container"
        className="faq-element3 accordion-element"
      >
        <div className="faq-details3">
          <span className="faq-text14">
            Can I use this for crypto marketing?
          </span>
          <span data-role="accordion-content" className="faq-text15">
            Yes, TweetMob is primarily used for crypto marketing - it is how
            projects get big with immediate traction. Grow a loyal community
            fast without relying on slow organic algorithms. Promote your token
            with an average 16x ROAS.
          </span>
        </div>
        <div data-role="accordion-icon">
          <svg viewBox="0 0 1024 1024" className="faq-icon14">
            <path d="M366 708l196-196-196-196 60-60 256 256-256 256z"></path>
          </svg>
        </div>
      </div>
      <div
        data-role="accordion-container"
        className="faq-element4 accordion-element"
      >
        <div className="faq-details4">
          <span className="faq-text16">What kind of CPMs can I expect?</span>
          <span data-role="accordion-content" className="faq-text17">
            Cost per 1000 views (CPM) averages less than $2 usd for Tier 1 Geos
            - high income countries such as USA, Europe, East Asia. This is
            typically cheaper than ads or paid influencers, with quality you
            control. While typical replies get 25 views, your targeting can get
            as low as $0.01 CPMs (~5000 views x 20 replies). Since you choose
            the targeting &amp; message, you can control the quality - use our
            AI campaign generator to help.
          </span>
        </div>
        <div data-role="accordion-icon">
          <svg viewBox="0 0 1024 1024" className="faq-icon16">
            <path d="M366 708l196-196-196-196 60-60 256 256-256 256z"></path>
          </svg>
        </div>
      </div>
      <div
        data-role="accordion-container"
        className="faq-element5 accordion-element"
      >
        <div className="faq-details5">
          <span className="faq-text18">What kind of ROAS can I expect?</span>
          <span data-role="accordion-content" className="faq-text19">
            Return on Ad Spend (ROAS) averages 16x for crypto marketing. That
            means every $1 of ad spend gets you $16 revenue back - this is based
            on token fundraising objectives. For direct sales of SaaS, services
            &amp; products, we typically see 2x - 6x ROAS.
          </span>
        </div>
        <div data-role="accordion-icon">
          <svg viewBox="0 0 1024 1024" className="faq-icon18">
            <path d="M366 708l196-196-196-196 60-60 256 256-256 256z"></path>
          </svg>
        </div>
      </div>
      <div
        data-role="accordion-container"
        className="faq-element6 accordion-element"
      >
        <div className="faq-details6">
          <span className="faq-text20">Can I bring my own accounts?</span>
          <span data-role="accordion-content" className="faq-text21">
            Yes, you can use our &quot;Sybil Now&quot; feature to post from your
            own accounts. However we also provide 1000s of human verified
            socials to spread your message. You can &quot;spearfish or
            shotgun&quot; your promotions across X Twitter.
          </span>
        </div>
        <div data-role="accordion-icon">
          <svg viewBox="0 0 1024 1024" className="faq-icon20">
            <path d="M366 708l196-196-196-196 60-60 256 256-256 256z"></path>
          </svg>
        </div>
      </div>
      <div
        data-role="accordion-container"
        className="faq-element7 accordion-element"
      >
        <div className="faq-details7">
          <span className="faq-text22">Why is there a waitlist?</span>
          <span data-role="accordion-content" className="faq-text23">
            Procuring quality human verified accounts takes time and money. To
            keep our services affordable, we onboard paid volunteers based on
            customer demand. Join our waitlist asap to get priority - first come
            first serve.
          </span>
        </div>
        <div data-role="accordion-icon">
          <svg viewBox="0 0 1024 1024" className="faq-icon22">
            <path d="M366 708l196-196-196-196 60-60 256 256-256 256z"></path>
          </svg>
        </div>
      </div>
    </div>
  )
}

FAQ.defaultProps = {
  rootClassName: '',
}

FAQ.propTypes = {
  rootClassName: PropTypes.string,
}

export default FAQ
