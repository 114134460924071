import React, { Fragment } from "react";

import Script from "dangerous-html/react";
import { Helmet } from "react-helmet";

import Navbar from "../components/navbar";
import Mark from "../components/mark";
import Card from "../components/card";
import Accordion from "../components/accordion";
import Includes from "../components/includes";
import Excludes from "../components/excludes";
import Review from "../components/review";
import Article from "../components/article";
import FAQ from "../components/faq";
import "./home.css";

const Home = (props) => {
  return (
    <div className="home-container1">
      <Helmet>
        <title>TweetMob - Astroturfing API</title>
        <meta
          name="description"
          content="Spread your message fast. Post from 1000s of socials. #1 Astroturfing API for X"
        />
        <meta property="og:title" content="TweetMob - Astroturfing API" />
        <meta
          property="og:description"
          content="Spread your message fast. Post from 1000s of socials. #1 Astroturfing API for X"
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/a3ee83c8-ee35-4173-821e-92893f54ac98/d53fcf7f-d246-4491-b02f-5bb60816ab12?org_if_sml=1&amp;force_format=original"
        />
      </Helmet>
      <Navbar rootClassName="navbarroot-class-name"></Navbar>
      <section className="home-section10">
        <div className="home-hero">
          <div className="home-content1">
            <main className="home-main1">
              <header className="home-header10">
                <h1 className="home-heading10">Spread Your Message Fast</h1>
                <span className="home-caption1">
                  <span className="home-text10">
                    Promote your product from 1000 of socials.
                  </span>
                  <br></br>
                  <span>Spreadsheet to Twitter actions.</span>
                  <br></br>
                </span>
              </header>
              <div className="home-buttons1">
                <a
                  href="https://t.me/TweetMob_bot"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="home-link10"
                >
                  <div className="home-get-started1 button">
                    <span className="home-text14">Tweet Now</span>
                  </div>
                </a>
                <a
                  href="https://www.youtube.com/playlist?list=PLmMjLXRgon2XQ5WtOGjwN9rsuFHGhHnmy"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="home-link11"
                >
                  <div className="home-get-started2 button">
                    <span className="home-text15">Watch Tutorial</span>
                  </div>
                </a>
              </div>
            </main>
            <div className="home-highlight">
              <div className="home-avatars">
                <img
                  alt="image"
                  src="https://images.unsplash.com/photo-1552234994-66ba234fd567?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDN8fHBvdHJhaXR8ZW58MHx8fHwxNjY3MjQ0ODcx&amp;ixlib=rb-4.0.3&amp;w=200"
                  className="home-image10 avatar"
                />
                <img
                  alt="image"
                  src="https://images.unsplash.com/photo-1610276198568-eb6d0ff53e48?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDF8fHBvdHJhaXR8ZW58MHx8fHwxNjY3MjQ0ODcx&amp;ixlib=rb-4.0.3&amp;w=200"
                  className="home-image11 avatar"
                />
                <img
                  alt="image"
                  src="https://images.unsplash.com/photo-1618151313441-bc79b11e5090?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDEzfHxwb3RyYWl0fGVufDB8fHx8MTY2NzI0NDg3MQ&amp;ixlib=rb-4.0.3&amp;w=200"
                  className="home-image12 avatar"
                />
              </div>
              <label className="home-caption2">
                Post from 1000+ provided accounts
              </label>
            </div>
          </div>
          <div className="home-image13">
            <img alt="image" src="/sybil-1000w.png" className="home-image14" />
          </div>
          <div className="home-image15">
            <img alt="image" src="/sybil-1000w.png" className="home-image16" />
          </div>
        </div>
      </section>
      <section id="features" className="home-section11">
        <h2 className="home-text16">Get Known Fast</h2>
        <div className="home-features1">
          <header className="feature feature-active home-feature1">
            <h3 className="home-text17">$0.05 per comment</h3>
            <p className="home-text18">Custom comments delivered a la carte</p>
          </header>
          <header className="feature home-feature2">
            <h3 className="home-text19">Easy quality control</h3>
            <p className="home-text20">
              AI generated comments with rapid human review 
            </p>
          </header>
          <header className="feature home-feature3">
            <h3 className="home-text21">AI customer discovery</h3>
            <p className="home-text22">Targeting your niche &amp; offer</p>
          </header>
        </div>
        <div className="home-note1">
          <div className="home-content2">
            <main className="home-main2">
              <h2 className="home-heading11">Stop Waiting to get Noticed</h2>
              <p className="home-paragraph1">
                <span>Get your product in front of real people today. </span>
                <br></br>
                <br></br>
                <span>
                  TweetMob provides 1000s of social media accounts for direct
                  response marketing in Twitter threads.
                  <span
                    dangerouslySetInnerHTML={{
                      __html: " ",
                    }}
                  />
                </span>
                <br></br>
                <br></br>
                <span>
                  We provide the accounts, we find and tag your prospects.
                </span>
                <br></br>
                <span>Your message delivered.</span>
                <br></br>
                <br></br>
                <span>Easy to get started using our AI generator.</span>
                <br></br>
              </p>
            </main>
            <a
              href="https://t.me/TweetMob_bot"
              target="_blank"
              rel="noreferrer noopener"
              className="home-link12"
            >
              <div className="home-explore-more">
                <p className="home-text36">Get Started -&gt;</p>
              </div>
            </a>
          </div>
          <div className="home-image17">
            <div className="home-container2">
              <div className="home-container3">
                <Script
                  html={`<iframe src="https://docs.google.com/spreadsheets/d/1uNJDSVl4Bzce7wXbdqjRLvCkuNBF2VgHJEdiYBpzTZs/edit?usp=sharing" style="width:100%;height:100%;" />`}
                ></Script>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="home-section12">
        <header className="home-header11">
          <h2 className="home-text37">Same Day Results</h2>
          <span className="home-text38">
            Immediately get exposure &amp; quickly test your messaging
          </span>
        </header>
        <section className="home-note2">
          <div className="home-image18">
            <img
              alt="image"
              src="/niche-followers-1200w.png"
              className="home-image19"
            />
          </div>
          <div className="home-content3">
            <div className="home-main3">
              <div className="home-caption3">
                <span className="section-head">EASY customer DISCOVERY</span>
              </div>
              <div className="home-heading12">
                <h2 className="section-heading">Target Niche Followers</h2>
                <p className="section-description">
                  <span>
                    AI Webscraping of follower lists, search feeds &amp;
                    mentions.
                  </span>
                  <br></br>
                  <span>
                    TweetMob provides everything you need to find targets
                    customers.
                    <span
                      dangerouslySetInnerHTML={{
                        __html: " ",
                      }}
                    />
                  </span>
                </p>
              </div>
            </div>
            <a
              href="https://tally.so/r/w5GkKZ"
              target="_blank"
              rel="noreferrer noopener"
              className="home-link13"
            >
              <div className="home-get-started3 button">
                <span className="home-text42">DOWNLOAD LIST</span>
              </div>
            </a>
          </div>
        </section>
        <section className="home-note3">
          <div className="home-image20">
            <img alt="image" src="/sybil-1000w.png" className="home-image21" />
          </div>
          <div className="home-content4">
            <main className="home-main4">
              <header className="home-caption4">
                <span className="home-section14 section-head">Custom copy</span>
              </header>
              <main className="home-heading14">
                <header className="home-header12">
                  <h2 className="section-heading">Message Delivery</h2>
                  <p className="section-description">
                    You decide the message &amp; we deliver. TweetMob provides
                    1000s of social media accounts to deliver your message.
                  </p>
                </header>
                <div className="home-checkmarks">
                  <Mark
                    label="Custom Messages"
                    label1={
                      <Fragment>
                        <span className="home-label1">Custom Messages</span>
                      </Fragment>
                    }
                  ></Mark>
                  <Mark
                    label="Custom Messages"
                    label1={
                      <Fragment>
                        <span className="home-label2">
                          <span>No Minimum Order</span>
                          <br></br>
                        </span>
                      </Fragment>
                    }
                  ></Mark>
                  <Mark
                    label="No Minimum Order"
                    label1={
                      <Fragment>
                        <span className="home-label3">Same day delivery</span>
                      </Fragment>
                    }
                  ></Mark>
                </div>
              </main>
            </main>
            <a
              href="https://t.me/TweetMob_bot"
              target="_blank"
              rel="noreferrer noopener"
              className="home-link14"
            >
              <div className="home-get-started4 button">
                <span className="home-text45">Tweet Now</span>
              </div>
            </a>
          </div>
        </section>
      </section>
      <section className="home-section15">
        <header className="home-header13">
          <header className="home-left1">
            <span className="section-head">Marketing toolkit</span>
            <h2 className="section-heading">TweetMob Provides</h2>
          </header>
          <div className="home-right1">
            <p className="home-paragraph4 section-description">
              <span>Everything you need to promote your product fast</span>
              <br></br>
              <span>No ad accounts, no KYC, instant delivery</span>
            </p>
          </div>
        </header>
        <main className="home-cards1">
          <Card
            header="Social Accounts"
            description="TweetMob provides 1000s of verified Twitter profiles to spread your message."
            rootClassName="cardroot-class-name"
          ></Card>
          <Card
            icon="/Icons/group%201314-200h.png"
            header="Message Delivery"
            description="Spread your message in Twitter threads, replys &amp; mentions. We don't get hidden in spam."
            rootClassName="cardroot-class-name1"
          ></Card>
          <Card
            icon="/Icons/group%201317-200h.png"
            header="Customer Discovery"
            description="Quickly find your ideal customers using AI webscraping &amp; filtering."
            rootClassName="cardroot-class-name2"
          ></Card>
        </main>
      </section>
      <section className="home-section17">
        <div className="home-note4">
          <div className="home-image22">
            <img
              alt="image"
              src="/SectionImages/iphone%2014%20pro%20max-1200w.png"
              className="home-image23"
            />
          </div>
          <div className="home-content5">
            <div className="home-caption5">
              <span className="section-head">Tempor incididunt</span>
            </div>
            <div className="home-heading17">
              <div className="home-header14">
                <h2 className="section-heading">
                  Tips to get care, answers and advice faster
                </h2>
              </div>
              <Accordion rootClassName="accordionroot-class-name"></Accordion>
            </div>
          </div>
        </div>
      </section>
      <section className="home-section19">
        <div className="home-cube1">
          <div className="home-top1 side"></div>
          <div className="home-front1 side"></div>
          <div className="home-left2 side"></div>
        </div>
        <main className="home-banner">
          <div className="home-header15">
            <h2 className="section-heading">Easy Spreadsheet Campaigns</h2>
            <p className="home-description1 section-description">
              Your entire campaign managed on a Google Spreadsheet. Simple &amp;
              Fast
            </p>
          </div>
          <div className="home-buttons2">
            <a
              href="https://t.me/TweetMob_bot"
              target="_blank"
              rel="noreferrer noopener"
              className="home-link15"
            >
              <div className="home-get-started5 button">
                <span className="home-text49">Get Started</span>
              </div>
            </a>
            <a
              href="https://www.youtube.com/playlist?list=PLmMjLXRgon2XQ5WtOGjwN9rsuFHGhHnmy"
              target="_blank"
              rel="noreferrer noopener"
              className="home-link16"
            >
              <div className="home-book-demo button">
                <span className="home-text50">Watch Tutorial</span>
              </div>
            </a>
          </div>
        </main>
      </section>
      <section id="pricing" className="home-section20">
        <div className="home-cube2">
          <div className="home-top2 side"></div>
          <div className="home-front2 side"></div>
          <div className="home-left3 side"></div>
        </div>
        <main className="home-pricing1">
          <header className="home-header16">
            <header className="home-left4">
              <span className="section-head">Pricing</span>
              <h2 className="section-heading home-heading20">
                Affordable Pricing
              </h2>
            </header>
            <div className="home-right2">
              <p className="home-paragraph5 section-description">
                Quality Delivery at a Fair Price. Bulk discounts available.
              </p>
            </div>
          </header>
          <div className="home-plans-container">
            <main className="home-plans">
              <div className="home-plan1">
                <div className="home-details1">
                  <div className="home-header17">
                    <label className="home-name1">Campaign Builder</label>
                    <div className="home-pricing2">
                      <span className="home-price1">Free</span>
                    </div>
                  </div>
                  <p className="home-description2">
                    Plan your campaign on a free spreadsheet database.
                  </p>
                </div>
                <div className="home-buy-details1">
                  <a
                    href="https://t.me/TweetMob_bot"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="home-link17"
                  >
                    <div className="home-buy1 button">
                      <span className="home-text51">
                        <span>Get Started</span>
                        <br></br>
                      </span>
                    </div>
                  </a>
                  <div className="home-features2">
                    <span className="home-heading21">You will get</span>
                    <div className="home-list1">
                      <Includes
                        label="Spreadsheet campaign template"
                        rootClassName="includesroot-class-name"
                      ></Includes>
                      <Includes
                        label="Interactive leads database"
                        rootClassName="includesroot-class-name"
                      ></Includes>
                      <Includes
                        label="Best practices guide"
                        rootClassName="includesroot-class-name"
                      ></Includes>
                    </div>
                  </div>
                </div>
              </div>
              <div className="home-plan2">
                <div className="home-details2">
                  <div className="home-header18">
                    <label className="home-name2">Message Delivery</label>
                    <div className="home-pricing3">
                      <h1 className="home-price2">$0.05</h1>
                      <span className="home-duration1">/comment</span>
                    </div>
                  </div>
                  <p className="home-description3">
                    Spread your message across X Twitter with authenticity.
                  </p>
                </div>
                <div className="home-buy-details2">
                  <a
                    href="https://t.me/TweetMob_bot"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="home-link18"
                  >
                    <div className="home-buy2 button">
                      <span className="home-text54">
                        <span>Get Started</span>
                        <br></br>
                      </span>
                    </div>
                  </a>
                  <div className="home-features3">
                    <span className="home-heading22">You will get</span>
                    <div className="home-list2">
                      <Includes
                        label="Tweet posting"
                        rootClassName="includesroot-class-name"
                      ></Includes>
                      <Includes
                        label="Custom messages"
                        rootClassName="includesroot-class-name"
                      ></Includes>
                      <Includes
                        label="Thread reply targeting"
                        rootClassName="includesroot-class-name"
                      ></Includes>
                      <Includes
                        label="Quote reply targeting"
                        rootClassName="includesroot-class-name"
                      ></Includes>
                      <Includes
                        label="Tag mentions"
                        rootClassName="includesroot-class-name21"
                      ></Includes>
                      <Excludes
                        label="Inbox DM"
                        rootClassName="excludesroot-class-name"
                      ></Excludes>
                    </div>
                  </div>
                </div>
              </div>
              <div className="home-plan3">
                <div className="home-details3">
                  <div className="home-header19">
                    <label className="home-name3">AI Customer Discovery</label>
                    <div className="home-pricing4">
                      <h1 className="home-price3">$1</h1>
                      <span className="home-duration2">/1k followers</span>
                    </div>
                  </div>
                  <p className="home-description4">
                    Download customer lists with AI filtering. Humans only!
                  </p>
                </div>
                <div className="home-buy-details3">
                  <a
                    href="https://t.me/TweetMob_bot"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="home-link19"
                  >
                    <div className="home-buy3 button">
                      <span className="home-text57">
                        <span>Get Started</span>
                        <br></br>
                      </span>
                    </div>
                  </a>
                  <div className="home-features4">
                    <span className="home-heading23">You will get</span>
                    <div className="home-list3">
                      <Includes
                        label="Real customer discovery"
                        rootClassName="includesroot-class-name"
                      ></Includes>
                      <Includes
                        label="AI web scraping lists"
                        rootClassName="includesroot-class-name"
                      ></Includes>
                      <Includes
                        label="Ignore bots &amp; spam"
                        rootClassName="includesroot-class-name"
                      ></Includes>
                      <Includes
                        label="Target followers, lists &amp; search"
                        rootClassName="includesroot-class-name"
                      ></Includes>
                      <Includes
                        label="Quality CPM audience"
                        rootClassName="includesroot-class-name"
                      ></Includes>
                      <Includes
                        label="Profitable ROAS"
                        rootClassName="includesroot-class-name"
                      ></Includes>
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </main>
        <div className="home-help">
          <span className="home-text60">
            <span>Need any help?</span>
            <br></br>
          </span>
          <a
            href="https://tally.so/r/w5GkKZ"
            target="_blank"
            rel="noreferrer noopener"
            className="home-link20"
          >
            <div className="home-contact-support">
              <p className="home-text63">Contact support -&gt;</p>
            </div>
          </a>
        </div>
      </section>
      <section className="home-section22">
        <header className="home-header20">
          <header className="home-left5">
            <span className="section-head">Tempor incididunt</span>
            <h2 className="home-heading24 section-heading">
              What users say about us
            </h2>
          </header>
          <div className="home-right3">
            <p className="home-paragraph6 section-description">
              Sed ut perspiciatis unde omnis iste natus error sit voluptatem
              accusantium doloremque laudantium, totam rem aperiam.
            </p>
          </div>
        </header>
        <main className="home-cards2">
          <div className="home-container4">
            <Review rootClassName="reviewroot-class-name"></Review>
            <Review
              quote="“Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur.\u2028\u2028Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur.”"
              rootClassName="reviewroot-class-name"
            ></Review>
          </div>
          <div className="home-container5">
            <Review
              quote="“Illum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.”"
              rootClassName="reviewroot-class-name"
            ></Review>
            <Review
              quote="“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.”"
              rootClassName="reviewroot-class-name"
            ></Review>
          </div>
          <div className="home-container6">
            <Review
              quote="“Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae.”"
              rootClassName="reviewroot-class-name"
            ></Review>
            <Review
              quote="“Doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae.”"
              rootClassName="reviewroot-class-name"
            ></Review>
          </div>
        </main>
        <div className="home-view-more">
          <p className="home-text64">View more</p>
        </div>
      </section>
      <section className="home-section24">
        <header className="home-header21">
          <span className="section-head">Articles about us</span>
          <h2 className="home-heading25 section-heading">
            We’re the app on everyone’s lips
          </h2>
        </header>
        <main className="home-cards3">
          <Article rootClassName="articleroot-class-name"></Article>
          <Article
            header="techeu"
            specialHeader="eu"
            rootClassName="articleroot-class-name"
          ></Article>
          <Article
            header="sifted/"
            rootClassName="articleroot-class-name"
          ></Article>
        </main>
      </section>
      <section id="faq" className="home-section26">
        <header className="home-header22">
          <span className="section-head">FAQ</span>
          <h2 className="home-heading26 section-heading">
            Frequently asked questions
          </h2>
        </header>
        <main className="home-accordion">
          <FAQ rootClassName="fa-qroot-class-name"></FAQ>
        </main>
      </section>
      <section className="home-section28">
        <main className="home-content6">
          <header className="home-header23">
            <h2 className="section-heading">
              <span>Spread your message fast</span>
              <br></br>
              <span className="home-text67">#1 Astroturfing API for X</span>
            </h2>
            <div className="home-buttons3">
              <a
                href="https://t.me/TweetMob_bot"
                target="_blank"
                rel="noreferrer noopener"
                className="home-link21"
              >
                <div className="home-ios button">
                  <span className="home-text68">Tweet Now</span>
                </div>
              </a>
            </div>
          </header>
          <img alt="image" src="/sybil-1000w.png" className="home-image24" />
        </main>
      </section>
      <footer className="home-footer">
        <div className="home-content7">
          <main className="home-main-content">
            <div className="home-content8">
              <header className="home-main5">
                <div className="home-header24">
                  <img
                    alt="image"
                    src="/%C3%B0%C2%9F%C2%8D%C2%80%20tweetmob-200h.png"
                    className="home-branding"
                  />
                  <span className="home-text69">
                    <span>Spread your message fast</span>
                    <br></br>
                    <span>#1 Astroturfing API for X</span>
                  </span>
                </div>
                <div className="home-socials">
                  <a
                    href="https://example.com"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="home-link22"
                  >
                    <img
                      alt="image"
                      src="/Icons/linkedin-200h.png"
                      className="home-linked-in social"
                    />
                  </a>
                  <a
                    href="https://example.com"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="home-link23"
                  >
                    <img
                      alt="image"
                      src="/Icons/instagram-200h.png"
                      className="home-instagram social"
                    />
                  </a>
                  <a
                    href="https://x.com/runsybil_"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="home-link24"
                  >
                    <img
                      alt="image"
                      src="/Icons/twitter-200h.png"
                      className="home-twitter social"
                    />
                  </a>
                </div>
              </header>
              <header className="home-categories">
                <div className="home-category1">
                  <div className="home-header25">
                    <span className="footer-header">Solutions</span>
                  </div>
                  <div className="home-links1">
                    <span className="footer-link">Astroturfing API </span>
                    <span className="footer-link">Campaign Builder</span>
                    <span className="footer-link">Tweet Delivery</span>
                    <span className="footer-link">AI Customer Discovery</span>
                  </div>
                </div>
                <div className="home-category2">
                  <div className="home-header26">
                    <span className="footer-header">Company</span>
                  </div>
                  <div className="home-links2">
                    <span className="footer-link">Contact Us</span>
                    <span className="footer-link">admin@runsybil.run</span>
                    <span className="footer-link">X @tweetmob_</span>
                  </div>
                </div>
              </header>
            </div>
            <section className="home-copyright1">
              <span className="home-text82">Copyright © 2024 TweetMob</span>
            </section>
          </main>
          <main className="home-subscribe">
            <main className="home-main6">
              <h1 className="home-heading28">Subscribe to our newsletter</h1>
              <div className="home-input-field">
                <input
                  type="email"
                  placeholder="Enter your email"
                  className="home-textinput input"
                />
                <div className="home-buy4 button">
                  <span className="home-text83">-&gt;</span>
                  <span className="home-text84">
                    <span>Subscribe now</span>
                    <br></br>
                  </span>
                </div>
              </div>
            </main>
            <h1 className="home-notice">
              By subscribing to our newsletter you agree with our Terms and
              Conditions.
            </h1>
          </main>
          <section className="home-copyright2">
            <span className="home-text87">Copyright © 2024 RunSybil</span>
          </section>
        </div>
      </footer>
      <div>
        <div className="home-container8">
          <Script
            html={`<script>
    /*
Accordion - Code Embed
*/

/* listenForUrlChangesAccordion() makes sure that if you changes pages inside your app,
the Accordions will still work*/

const listenForUrlChangesAccordion = () => {
      let url = location.href;
      document.body.addEventListener(
        "click",
        () => {
          requestAnimationFrame(() => {
            if (url !== location.href) {
              runAccordionCodeEmbed();
              url = location.href;
            }
          });
        },
        true
      );
    };


const runAccordionCodeEmbed = () => {
    const accordionContainers = document.querySelectorAll('[data-role="accordion-container"]'); // All accordion containers
    const accordionContents = document.querySelectorAll('[data-role="accordion-content"]'); // All accordion content
    const accordionIcons = document.querySelectorAll('[data-role="accordion-icon"]'); // All accordion icons

    accordionContents.forEach((accordionContent) => {
        accordionContent.style.display = "none"; //Hides all accordion contents
    });

    accordionContainers.forEach((accordionContainer, index) => {
        accordionContainer.addEventListener("click", () => {
            accordionContents.forEach((accordionContent) => {
            accordionContent.style.display = "none"; //Hides all accordion contents
            });

            accordionIcons.forEach((accordionIcon) => {
                accordionIcon.style.transform = "rotate(0deg)"; // Resets all icon transforms to 0deg (default)
            });

            accordionContents[index].style.display = "flex"; // Shows accordion content
            accordionIcons[index].style.transform = "rotate(180deg)"; // Rotates accordion icon 180deg
        });
    });
}

runAccordionCodeEmbed()
listenForUrlChangesAccordion()

/*
Here's what the above is doing:
    1. Selects all accordion containers, contents, and icons
    2. Hides all accordion contents
    3. Adds an event listener to each accordion container
    4. When an accordion container is clicked, it:
        - Hides all accordion contents
        - Resets all icon transforms to 0deg (default)
        - Checks if this container has class "accordion-open"
            - If it does, it removes class "accordion-open"
            - If it doesn't, it:
                - Removes class "accordion-open" from all containers
                - Adds class "accordion-open" to this container
                - Shows accordion content
                - Rotates accordion icon 180deg
*/
</script>`}
          ></Script>
        </div>
      </div>
    </div>
  );
};

export default Home;
